import React from "react"
import { Link } from "gatsby"

import collapseMenuStyles from "./collapseMenu.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { useSpring, animated, Trail } from "react-spring"
import HoverMenu from "../hoverMenu/hoverMenu"

function CollapseMenu(props) {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 })

  const CollapseWrapper = animated.div



  const items = [
    <div className={collapseMenuStyles.mainLinks}>
      <Link
        className={collapseMenuStyles.linkCat}
        activeClassName={collapseMenuStyles.linkCatActive}
        to="/women"
        onClick={props.handleNavbar}
      >
        <div className={collapseMenuStyles.linkTextCat}>Women</div>
      </Link>
      <button
        className={collapseMenuStyles.mobileMenuButton}
        onClick={() => props.setOpenMobileMenu("Women")}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>,
    <div className={collapseMenuStyles.mainLinks}>
      <Link
        className={collapseMenuStyles.linkCat}
        activeClassName={collapseMenuStyles.linkCatActive}
        to="/plus"
        onClick={props.handleNavbar}
      >
        <div className={collapseMenuStyles.linkTextCat}>Plus</div>
      </Link>
      <button
        className={collapseMenuStyles.mobileMenuButton}
        onClick={() => props.setOpenMobileMenu("Plus")}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>,
    <div className={collapseMenuStyles.mainLinks}>
      <Link
        className={collapseMenuStyles.linkCat}
        activeClassName={collapseMenuStyles.linkCatActive}
        to="/maternity"
        onClick={props.handleNavbar}
      >
        <div className={collapseMenuStyles.linkTextCat}>Maternity</div>
      </Link>
      <button
        className={collapseMenuStyles.mobileMenuButton}
        onClick={() => props.setOpenMobileMenu("Maternity")}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>,
    <div className={collapseMenuStyles.division}></div>,
    <div className={collapseMenuStyles.linkMenuNew}>
      <Link
        className={collapseMenuStyles.linkNew}
        activeClassName={collapseMenuStyles.linkActive}
        to="/new-arrivals"
        onClick={props.handleNavbar}
      >
        <div className={collapseMenuStyles.linkTextNew}>New Arrivals</div>
      </Link>
      <button
        className={collapseMenuStyles.linkMenuButton}
        onClick={() => props.setOpenMobileMenu("New Arrivals")}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>,
    <Link
      className={collapseMenuStyles.link}
      activeClassName={collapseMenuStyles.linkActive}
      to="/about"
      onClick={props.handleNavbar}
    >
      <div className={collapseMenuStyles.linkText}>About Us</div>
    </Link>,
    <Link
      className={collapseMenuStyles.link}
      activeClassName={collapseMenuStyles.linkActive}
      to="/faq"
      onClick={props.handleNavbar}
    >
      <div className={collapseMenuStyles.linkText}>FAQ</div>
    </Link>,
  ]

  if (props.navbarState === true) {
    return (
      <>
        {props.openMobileMenu ? (
          <HoverMenu
            collection={props.openMobileMenu}
            handleClose={() => props.setOpenMobileMenu(false)}
            isMobile={true}
          />
        ) : null}
        <CollapseWrapper
          className={collapseMenuStyles.collapseWrapper}
          style={{
            transform: open
              .to({
                range: [0, 0.2, 0.3, 1],
                output: [0, -20, 0, -200],
              })
              .to(openValue => `translate3d(${openValue}px, 0, 0`),
          }}
        >
          <ul className={collapseMenuStyles.links}>
            <Trail
              items={items}
              keys={items.map((_, i) => i)}
              from={{ transform: "translate3d(-20px,-40px,0)" }}
              to={{ transform: "translate3d(0,0px,0)" }}
            >
              {item => props => <li style={props}>{item}</li>}
            </Trail>
          </ul>
        </CollapseWrapper>
      </>
    )
  }
  return null
}

export default CollapseMenu
