import React, { useContext } from "react"
import {Link} from 'gatsby'

import cartMenuStyles from "./cartMenu.module.scss"
import StoreContext from "../../../utilities/context/storeContext"

import { useSpring, animated, Trail } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons"
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons"
import { faHeartBroken } from "@fortawesome/free-solid-svg-icons"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import fbTrack from "../../../utilities/pixel/fbTrack"

function CartMenu(props) {
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const { open } = useSpring({ open: props.cartState ? 0 : 1 })

  const CollapseWrapper = animated.div

  const lineItems = checkout.lineItems.map((item, i) => {
    let handleRemove = () => {
      removeLineItem(client, checkout.id, item.id)
    }
    return (
      <div key={`${item.id}-${i}`} className={cartMenuStyles.lineItemContainer}>
        <div className={cartMenuStyles.imageContainer}>
          <img
            src={item.variant.image.src}
            alt={`${item.title} product shot`}
            height="100px"
          />
        </div>
        <span className={cartMenuStyles.itemTitle}>{item.title}</span>
        <div className={cartMenuStyles.detailsContainer}>
          <span className={cartMenuStyles.itemVariant}>
            {item.variant.title}
          </span>
          <div className={cartMenuStyles.priceContainer}>
            {item.variant.compareAtPrice > item.variant.price ? (
              <span
                className={cartMenuStyles.comparePrice}
              >{`₱${item.variant.compareAtPrice
                .slice(0, -3)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
            ) : null}
            <span
              className={cartMenuStyles.price}
            >{`₱${item.variant.price
              .slice(0, -3)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
          </div>
        </div>
        <div className={cartMenuStyles.quantity}>
          <button
            onClick={() =>
              updateLineItem(client, checkout.id, item.id, item.quantity - 1)
            }
            className={cartMenuStyles.quantityToggle}
          >
            <FontAwesomeIcon icon={faMinusSquare} />
          </button>
          <span className={cartMenuStyles.quantityText}>{item.quantity}</span>
          <button
            onClick={() =>
              updateLineItem(client, checkout.id, item.id, item.quantity + 1)
            }
            className={cartMenuStyles.quantityToggle}
          >
            <FontAwesomeIcon icon={faPlusSquare} />
          </button>
        </div>
        <button className={cartMenuStyles.trash} onClick={handleRemove}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    )
  })

  const handleCheckout = () => {
    //google analytics
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Check Out",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      // label: "Check Out",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      // value: null,
    })

    //pixel analytics
    fbTrack("track", "InitiateCheckout")

    window.open(checkout.webUrl)
  }

  if (props.cartState === true) {
    return (
      <CollapseWrapper
        className={cartMenuStyles.collapseWrapper}
        style={{
          transform: open
            .to({
              range: [0, 0.2, 0.3, 1],
              output: [0, 20, 0, 200],
            })
            .to(openValue => `translate3d(${openValue}px, 0, 0`),
        }}
      >
        <Trail
          items={lineItems}
          keys={lineItems.map((_, i) => i)}
          from={{ transform: "translate3d( 20px, -40px,0)" }}
          to={{ transform: "translate3d(0,0,0)" }}
        >
          {item => props => (
            <div className={cartMenuStyles.itemsDiv} style={props}>
              {item}
            </div>
          )}
        </Trail>

        {checkout.lineItems.length > 0 ? (
          <div className={cartMenuStyles.checkout}>
            <div className={cartMenuStyles.totalContainer}>
              <h2 className={cartMenuStyles.totalHeading}>Total</h2>
              <span className={cartMenuStyles.totalPrice}>
                ₱ {checkout.totalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </div>
            <button
              className={cartMenuStyles.checkoutButton}
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0}
            >
              <span>Check out</span>
            </button>
          </div>
        ) : (
          <div className={cartMenuStyles.emptyCartContainer}>
          <div className={cartMenuStyles.heartBreak}>
            <FontAwesomeIcon icon={faHeartBroken} />
          </div>
            <span className={cartMenuStyles.cartEmpty}>
              Looks like your shopping bag is empty.
            </span>
            <Link to="/women" className={cartMenuStyles.cartEmptyButton}>
              Shop your look now
            </Link>
          </div>
        )}
      </CollapseWrapper>
    )
  }
  return null
}

export default CartMenu
