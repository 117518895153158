import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import footerStyles from "./footer.module.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { animateScroll as scroll } from "react-scroll"

function Footer() {
  const footerLogo = useStaticQuery(graphql`
    query {
      file(name: { eq: "White_Logo_Transparent" }) {
        childImageSharp {
          fixed(height: 65) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footerContainer}>
      <div className={footerStyles.innerContainer}>
        <div className={footerStyles.leftContainer}>
          <div className={footerStyles.social}>
            <h2 className={footerStyles.heading}>Social</h2>
            <a
              href="https://facebook.com/lillyfeatherph"
              target="_blank"
              rel="noreferrer"
              className={footerStyles.socialIcon}
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/lillyfeatherph/"
              target="_blank"
              rel="noreferrer"
              className={footerStyles.socialIcon}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <div className={footerStyles.info}>
            <h2 className={footerStyles.heading}>Info</h2>
            <Link to="/contact" className={footerStyles.infoLink}>
              Contact
            </Link>
            <Link to="/about" className={footerStyles.infoLink}>
              About
            </Link>
            <Link to="/faq" className={footerStyles.infoLink}>
              FAQ
            </Link>
          </div>
        </div>
        <div className={footerStyles.middleContainer}>
          <h2 className={footerStyles.tagline}>Be the Best Dressed</h2>
        </div>
        <div className={footerStyles.rightContainer}>
          <div className={footerStyles.address}>
            <span className={footerStyles.addressText}>
              3686 Sun Valley Dr, Subdivision, Parañaque, 1700 Metro Manila
            </span>
          </div>
          <div className={footerStyles.logoCopyright}>
            <Img
              fixed={footerLogo.file.childImageSharp.fixed}
              alt="Lilly Feather"
            />
            <span className={footerStyles.copyright}>©Lilly Feather 2020</span>
          </div>
        </div>
      </div>
      <button
        className={footerStyles.scrollToTopContainer}
        onClick={() => scroll.scrollToTop()}
      >
        <FontAwesomeIcon icon={faArrowUp} />
        <span className={footerStyles.scrollToTopText}>Back to Top</span>
      </button>
    </footer>
  )
}

export default Footer
