import React, { useEffect, useRef, useState, useContext } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import navbarStyles from "./navbar.module.scss"

import { useSpring, animated, config } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons"
import Burger from "../burger/burger"
import CollapseMenu from "../collapseMenu/collapseMenu.js"
import CartMenu from "../cartMenu/cartMenu.js"
import HoverMenu from "../hoverMenu/hoverMenu"
import StoreContext from "../../../utilities/context/storeContext"

const Navbar = props => {
  // const barAnimation = useSpring({
  //   from: { transform: "translate3d(0, -5rem, 0)" },
  //   transform: "translate3d(0, 0, 0)",
  //   delay: 50,
  // })

  const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 50,
    config: config.wobbly,
  })

  // const catAnimation = useSpring({
  //   from: { transform: "translate3d(-200px, 0, 0)", opacity: 0 },
  //   to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
  //   delay: 50,
  //   config: config.gentle,
  // })

  const cartAnimation = useSpring({
    transform: `rotateY(${props.cartState ? 360 : 0}deg)`,
    config: { tension: 150 },
  })

  // const NavBar = animated.nav
  const NavLinks = animated.ul
  // const NavLinksCat = animated.ul

  // // make sticky despite content on top of navbar
  // let stickyClasses = navbarStyles.navbar;

  // // When the user scrolls the page, execute myFunction
  // window.onscroll = function() {handleSticky()};

  // // Get the navbar
  // var navbar = document.getElementById("navbar");

  // // Get the offset position of the navbar
  // var sticky = navbar.offsetTop;

  // // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  // function handleSticky() {
  //   if (window.pageYOffset >= sticky) {
  //     stickyClasses = navbarStyles.sticky
  //   } else {
  //     stickyClasses = navbarStyles.sticky
  //   }
  // }

  const menuNode = useRef()

  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  // useEffect(() => {
  //   openMobileMenu
  //     ? console.log("true", openMobileMenu)
  //     : console.log("false", openMobileMenu)
  // },[openMobileMenu])

  useEffect(() => {
    //handle click outside when menu is open
    const handleClickOutside = e => {
      if (
        menuNode.current.contains(e.target) ||
        // openMobileMenu
        openMobileMenu === "Women" ||
        openMobileMenu === "Plus" ||
        openMobileMenu === "Maternity" ||
        openMobileMenu === "New Arrivals"
      ) {
        return
      }
      // outside click
      props.handleNavbar(false)
    }

    const handleResize = () => {
      if (props.navbarState) {
        props.handleNavbar(false)
      }
    }

    if (props.navbarState) {
      document.addEventListener("click", handleClickOutside)
      window.addEventListener("resize", handleResize)
    } else {
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("resize", handleResize)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.navbarState])

  const cartNode = useRef()
  useEffect(() => {
    //handle click outside when menu is open
    const handleClickOutside = e => {
      if (cartNode.current.contains(e.target)) {
        return
      }
      // outside click
      props.handleCart(false)
    }

    const handleResize = () => {
      if (props.handleCart) {
        props.handleCart(false)
      }
    }

    if (props.cartState) {
      document.addEventListener("click", handleClickOutside)
      window.addEventListener("resize", handleResize)
    } else {
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("resize", handleResize)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cartState])

  // const reloadPage = i => {
  //   navigate("/")
  // }

  // animate category links on hover
  const [hoverClothing, setHoverClothing] = useState("clothingFalse")
  const [hoverPlus, setHoverPlus] = useState("plusFalse")
  const [hoverMaternity, setHoverMaternity] = useState("maternityFalse")

  const handleClothingEnter = () => {
    setHoverClothing("clothingTrue")
    setHoverCollection("Women")
  }

  const handleClothingLeave = () => {
    setHoverCollection(null)
    setHoverClothing("clothingFalse")
  }

  const handlePlusEnter = () => {
    setHoverPlus("plusTrue")
    setHoverCollection("Plus")
  }

  const handlePlusLeave = () => {
    setHoverCollection(null)
    setHoverPlus("plusFalse")
  }

  const handleMaternityEnter = () => {
    setHoverMaternity("maternityTrue")
    setHoverCollection("Maternity")
  }

  const handleMaternityLeave = () => {
    setHoverCollection(null)
    setHoverMaternity("maternityFalse")
  }

  const clothingAnimation = useSpring({
    background:
      hoverClothing === "clothingTrue"
        ? "linear-gradient(360deg, rgba(220,74,117,1) 0%, rgba(112,17,107,1) 60%)"
        : "linear-gradient(0deg, rgba(220,74,117,1) 0%, rgba(112,17,107,1) 0%)",
    config: { mass: 3 },
  })

  const plusAnimation = useSpring({
    background:
      hoverPlus === "plusTrue"
        ? "linear-gradient(360deg, rgba(238,124,124,1) 0%, rgba(112,17,107,1) 60%)"
        : "linear-gradient(0deg, rgba(238,124,124,1) 0%, rgba(112,17,107,1) 0%)",
    config: { mass: 3 },
  })

  const maternityAnimation = useSpring({
    background:
      hoverMaternity === "maternityTrue"
        ? "linear-gradient(360deg, rgba(16,143,137,1) 0%, rgba(112,17,107,1) 60%)"
        : "linear-gradient(0deg, rgba(16,143,137,1) 0%, rgba(112,17,107,1) 0%)",
    config: { mass: 3 },
  })

  const Clothing = animated.div
  const Plus = animated.div
  const Maternity = animated.div

  const navbarLogo = useStaticQuery(graphql`
    query {
      file(name: { eq: "White_Logo_Transparent" }) {
        childImageSharp {
          fixed(height: 38) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  // const [scrollPositionHover, setScrollPositionHover] = useState(0)

  // useEffect(() => {
  //   const handleHoverMenuScroll = scrollPosition => {
  //     if (props.sticky) {
  //       setScrollPositionHover(60)
  //     } else {
  //       setScrollPositionHover(90 - scrollPosition)
  //     }
  //   }

  //   document.addEventListener(
  //     "scroll",
  //     () => handleHoverMenuScroll(window.scrollY),
  //     false
  //   )

  //   document.removeEventListener(
  //     "scroll",
  //     () => handleHoverMenuScroll(window.scrollY),
  //     false
  //   )
  // }, [props.sticky])

  const [hoverCollection, setHoverCollection] = useState(false)

  const hoverProps = useSpring({
    // transform: hover==="Women" || hover ==="Maternity" ? "translateY(0)" : "translateY(-200px)"
    // top:
    // hoverCollection === "New Arrivals" ||
    // hoverCollection === "Women" ||
    // hoverCollection === "Maternity" ||
    // hoverCollection === "Plus"
    //     ? '90px'
    //     : "-250px",

    transform:
      hoverCollection === "New Arrivals" ||
      hoverCollection === "Women" ||
      hoverCollection === "Maternity" ||
      hoverCollection === "Plus"
        ? "translateY(0)"
        : "translateY(-250px)",

    // opacity:
    //   hoverCollection === "New Arrivals" ||
    //   hoverCollection === "Women" ||
    //   hoverCollection === "Maternity" ||
    //   hoverCollection === "Plus"
    //     ? 1
    //     : 0,
  })

  const {
    store: { checkout },
  } = useContext(StoreContext)

  return (
    <>
      {/* <NavBar style={barAnimation} className={navbarStyles.navbar}> */}
      <nav
        id={props.id}
        className={navbarStyles.navbar}
        style={{
          position: props.sticky ? "fixed" : "relative",
          top: props.sticky ? "0px" : null,
        }}
      >
        <div className={navbarStyles.flexcontainer}>
          <div className={navbarStyles.burgerwrapper}>
            <Burger
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </div>
          <Link className={navbarStyles.navbarLogo} to="/">
            <div className={navbarStyles.navbarLogo}>
              <Img
                className={navbarStyles.navbarLogoImg}
                fixed={navbarLogo.file.childImageSharp.fixed}
                alt="Lilly Feather"
              />
            </div>
          </Link>
          <div className={navbarStyles.left}>
            <ul className={navbarStyles.navlinksCat}>
              <Link
                to={"/women"}
                className={navbarStyles.navlinkCat}
                activeClassName={navbarStyles.navlinkCatActive}
              >
                <Clothing
                  className={navbarStyles.navlinkCatDiv}
                  key={hoverClothing}
                  onMouseEnter={handleClothingEnter}
                  onMouseLeave={handleClothingLeave}
                  style={clothingAnimation}
                >
                  Women
                </Clothing>
              </Link>
              <Link
                to={"/plus"}
                className={navbarStyles.navlinkCat}
                activeClassName={navbarStyles.navlinkCatActive}
              >
                <Plus
                  className={navbarStyles.navlinkCatDiv}
                  onMouseEnter={handlePlusEnter}
                  onMouseLeave={handlePlusLeave}
                  key={hoverPlus}
                  style={plusAnimation}
                >
                  Plus
                </Plus>
              </Link>
              <Link
                to={"/maternity"}
                className={navbarStyles.navlinkCat}
                activeClassName={navbarStyles.navlinkCatActive}
              >
                <Maternity
                  className={navbarStyles.navlinkCatDiv}
                  key={hoverMaternity}
                  onMouseEnter={handleMaternityEnter}
                  onMouseLeave={handleMaternityLeave}
                  style={maternityAnimation}
                >
                  Maternity
                </Maternity>
              </Link>
            </ul>
          </div>
          <div className={navbarStyles.right}>
            <NavLinks className={navbarStyles.navlinks} style={linkAnimation}>
              <Link
                className={navbarStyles.navlink}
                activeClassName={navbarStyles.navlinkActive}
                to="/new-arrivals"
                onMouseEnter={() => setHoverCollection("New Arrivals")}
                onMouseLeave={() => setHoverCollection(null)}
              >
                New Arrivals
              </Link>
              <Link
                className={navbarStyles.navlink}
                activeClassName={navbarStyles.navlinkActive}
                to="/about"
              >
                About Us
              </Link>
              <Link
                className={navbarStyles.navlink}
                activeClassName={navbarStyles.navlinkActive}
                to="/faq"
              >
                FAQ
              </Link>
            </NavLinks>
            <div className={navbarStyles.cartCountBox}>
              <animated.div
                key={props.cartState}
                style={cartAnimation}
                state={`${props.cartState}`}
                className={navbarStyles.cartIcon}
                onClick={props.handleCart}
              >
                <FontAwesomeIcon icon={faShoppingBag} color="white" />
              </animated.div>
              {checkout.lineItems.length > 0 ? (
                <div className={navbarStyles.cartCountContainer}>
                  <span className={navbarStyles.cartCount}>
                    {checkout.lineItems.length}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <animated.div
          className={navbarStyles.hoverMenu}
          style={{
            ...hoverProps,
            display:
              hoverCollection === "New Arrivals" ||
              hoverCollection === "Women" ||
              hoverCollection === "Maternity" ||
              hoverCollection === "Plus"
                ? "block"
                : "none",
          }}
        >
          <HoverMenu
            collection={hoverCollection}
            setHoverCollection={setHoverCollection}
            isMobile={false}
          />
        </animated.div>
      </nav>
      <div ref={menuNode}>
        <CollapseMenu
          navbarState={props.navbarState}
          handleNavbar={props.handleNavbar}
          setOpenMobileMenu={setOpenMobileMenu}
          openMobileMenu={openMobileMenu}
        />
      </div>
      <animated.div ref={cartNode} className={navbarStyles.cartMenu}>
        <CartMenu cartState={props.cartState} handleCart={props.handleCart} />
      </animated.div>
    </>
  )
}

export default Navbar
