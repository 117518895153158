import React, { useState, useContext } from "react"
import productCardStyles from "./productCard.module.scss"

import Img from "gatsby-image"
import { useSpring, animated as a } from "react-spring"
import StoreContext from "../../utilities/context/storeContext"
import BounceLoader from "react-spinners/BounceLoader"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import fbTrack from '../../utilities/pixel/fbTrack'

function ProductCard({
  imgFront,
  imgBack,
  title,
  price,
  compareAt,
  sizes,
  type,
  text,
  imgHeight,
  imgWidth,
  textWidth,
}) {
  const {
    addVariantToCart,
    store: { adding },
  } = useContext(StoreContext)

  const [hover, setHover] = useState(false)
  const [bagHover, setBagHover] = useState(false)

  const { opacity } = useSpring({
    opacity: hover ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  const bagAnime = useSpring({
    opacity: bagHover ? 0 : 1,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  const noDeciCompare = compareAt.slice(0, -3)
  const prettyCompare = noDeciCompare.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  const noDeciPrice = price.slice(0, -2)
  const prettyPrice = noDeciPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  const handleAddToCart = (size, e) => {
    e.preventDefault()
    e.stopPropagation()
    addVariantToCart(size[1], 1)

    //google analytics
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: `${title}`,
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Add to Cart Product Card",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: size[1],
    })

    //pixel analytics
    fbTrack('track', 'AddToCart')

  }

  const renderSizes = () => {
    return sizes.map((size, i) => {
      return (
        <button
          onClick={e => handleAddToCart(size, e)}
          key={`${size}-${i}`}
          className={productCardStyles.addToBagSize}
        >
          {size[0]}
        </button>
      )
    })
  }

  return (
    <div
      className={productCardStyles.cardContainer}
      style={{ flexFlow: text === "side" ? "row nowrap" : "column nowrap" }}
    >
      <div
        className={productCardStyles.flipContainer}
        role="presentation"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          height: imgHeight ? `${imgHeight}px` : null,
          width: imgWidth ? `${imgWidth}px` : null,
        }}
      >
        <a.div
          className={productCardStyles.imageContainer}
          style={{ opacity: opacity.to(o => 1 - o) }}
        >
          {type === "fixed" ? (
            <Img fixed={imgFront} alt={title} />
          ) : (
            <Img fluid={imgFront} alt={title} />
          )}
        </a.div>
        <a.div
          className={productCardStyles.imageContainer}
          style={{
            opacity,
          }}
        >
          {type === "fixed" ? (
            <Img fixed={imgBack} alt={title} />
          ) : (
            <Img fluid={imgBack} alt={title} />
          )}
        </a.div>
        {prettyCompare > prettyPrice ? (
          <div className={productCardStyles.percentSave}>
            {`-${Math.round(
              ((Number(noDeciCompare) - Number(noDeciPrice)) /
                Number(noDeciCompare)) *
                100
            )}%`}
          </div>
        ) : null}
        <a.div
          className={productCardStyles.addToBagContainerBox}
          style={{
            opacity,
          }}
          role="button"
          tabIndex="0"
          onMouseEnter={() => setBagHover(true)}
          onMouseLeave={() => setBagHover(false)}
        >
          <a.div
            className={productCardStyles.addToBagContainer}
            style={bagAnime}
          >
            <span className={productCardStyles.addToBag}>Add to Bag</span>
          </a.div>
          <a.div
            className={productCardStyles.addToBagSizes}
            style={{
              justifyContent: adding ? "center" : "space-between",
              opacity: bagAnime.opacity.to(o => 1 - o),
            }}
          >
            {adding ? (
              <BounceLoader
                className={productCardStyles.spinner}
                size={50}
                color={"#70116b"}
                loading={true}
              />
            ) : (
              renderSizes()
            )}
          </a.div>
        </a.div>
      </div>
      <div
        className={productCardStyles.textContainer}
        style={{ width: textWidth ? `${textWidth}px` : null }}
      >
        <div className={productCardStyles.priceContainer}>
          {prettyCompare > prettyPrice ? (
            <span className={productCardStyles.compareAt}>
              {`₱${prettyCompare}`}
            </span>
          ) : null}
          <span
            className={productCardStyles.productPrice}
          >{`₱${prettyPrice}`}</span>
        </div>
        <span className={productCardStyles.productTitle}>{title}</span>
      </div>
    </div>
  )
}

export default ProductCard
