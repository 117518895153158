import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import announcementStyle from "./announcement.module.scss"

function Announcement() {
  const announcementQuery = useStaticQuery(graphql`
    query {
      allContentfulAnnouncement {
        edges {
          node {
            announcementText
            internalLink
            externalLink
          }
        }
      }
    }
  `)

  const announcementData = announcementQuery.allContentfulAnnouncement.edges

  const [announcement, setAnnouncement] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setAnnouncement(announcement =>
        announcement >= announcementData.length - 1 ? 0 : announcement + 1
      )
    }, 7000)
    return () => clearInterval(interval)
  }, [announcementData.length])

  return (
    <div className={announcementStyle.announcementBox}>
      {announcementData.map(({ node }, i) => {
        return (
          <React.Fragment key={`announcemnt-${i}`}>
            {node.internalLink !== "#" && node.externalLink === "#" ? (
              <Link
                className={announcementStyle.announcementButton}
                to={node.internalLink}
                style={{
                  opacity: announcement === i ? 1 : 0,
                  zIndex: announcement === i ? 10 : -1,
                  transform:
                    announcement === i ? "rotateX(0)" : "rotateX(360deg)",
                }}
              >
                <span className={announcementStyle.text}>
                  {node.announcementText}
                </span>
              </Link>
            ) : node.externalLink !== "#" && node.internalLink === "#" ? (
              <a
                href={node.externalLink}
                target="__blank"
                rel="noreferrer"
                style={{
                  opacity: announcement === i ? 1 : 0,
                  zIndex: announcement === i ? 10 : -1,
                  transform:
                    announcement === i ? "rotateX(0)" : "rotateX(360deg)",
                }}
              >
                <span className={announcementStyle.text}>
                  {node.announcementText}
                </span>
              </a>
            ) : (
              <Link
                className={announcementStyle.announcementButton}
                to="/"
                style={{
                  opacity: announcement === i ? 1 : 0,
                  zIndex: announcement === i ? 10 : -1,
                  transform:
                    announcement === i ? "rotateX(0)" : "rotateX(360deg)",
                }}
              >
                <span className={announcementStyle.text}>
                  {node.announcementText}
                </span>
              </Link>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Announcement
