import React, { useEffect, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import hoverMenuStyles from "./hoverMenu.module.scss"
import Img from "gatsby-image"
import ProductCard from "../../productCard/productCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

function HoverMenu({ collection, setHoverCollection, isMobile, handleClose }) {
  const menuQuery = useStaticQuery(graphql`
    query {
      women: shopifyCollection(title: { eq: "Women" }) {
        image {
          localFile {
            childImageSharp {
              fixed(height: 225) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        products {
          tags
        }
      }
      plus: shopifyCollection(title: { eq: "Plus" }) {
        image {
          localFile {
            childImageSharp {
              fixed(height: 225) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        products {
          tags
        }
      }
      maternity: shopifyCollection(title: { eq: "Maternity" }) {
        image {
          localFile {
            childImageSharp {
              fixed(height: 225) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        products {
          tags
        }
      }
      nwomen: shopifyCollection(title: { eq: "New Arrivals Women" }) {
        image {
          localFile {
            childImageSharp {
              fixed(height: 225) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fixed(height: 225) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            selectedOptions {
              name
              value
            }
            shopifyId
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
      nplus: shopifyCollection(title: { eq: "New Arrivals Plus" }) {
        image {
          localFile {
            childImageSharp {
              fixed(height: 225) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fixed(height: 225) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            selectedOptions {
              name
              value
            }
            shopifyId
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
      nmaternity: shopifyCollection(title: { eq: "New Arrivals Maternity" }) {
        image {
          localFile {
            childImageSharp {
              fixed(height: 225) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        products {
          id
          handle
          title
          images {
            localFile {
              childImageSharp {
                fixed(height: 225) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            selectedOptions {
              name
              value
            }
            shopifyId
            compareAtPrice
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
          options {
            name
            values
          }
        }
      }
    }
  `)

  const collectionTags =
    collection === "Women"
      ? menuQuery.women.products
      : collection === "Maternity"
      ? menuQuery.maternity.products
      : menuQuery.plus.products

  const collectionImage =
    collection === "Women"
      ? menuQuery.women.image.localFile.childImageSharp.fixed
      : collection === "Maternity"
      ? menuQuery.maternity.image.localFile.childImageSharp.fixed
      : menuQuery.plus.image.localFile.childImageSharp.fixed

  const [tags, setTags] = useState([])

  useEffect(() => {
    let initTags = []
    const initializeTags = () =>
      collectionTags.forEach(product => {
        product.tags
          .filter(tag => initTags.indexOf(tag) < 0)
          .forEach(tag => {
            initTags = [...initTags, tag]
          })
      })

    initializeTags()
    setTags(initTags)
  }, [collectionTags])

  const renderCollection = () => {
    return tags.map((tag, i) => {
      return (
        <Link
          to={`/${collection}/${tag}`.toLowerCase().replace(/ /g, "-")}
          key={`${collection}-${tag}-${i}`}
          className={hoverMenuStyles.tags}
          activeClassName={hoverMenuStyles.tagsActive}
        >
          {tag}
        </Link>
      )
    })
  }

  //New Arrivals menu
  const [newArrivalsCollection, setNewArrivalsCollection] = useState("Women")

  const newCollectionData =
    newArrivalsCollection === "Women"
      ? menuQuery.nwomen.products
      : newArrivalsCollection === "Plus"
      ? menuQuery.nplus.products
      : menuQuery.nmaternity.products

  const newCollectionMainImg =
    newArrivalsCollection === "Women"
      ? menuQuery.nwomen.image.localFile.childImageSharp.fixed
      : newArrivalsCollection === "Plus"
      ? menuQuery.nplus.image.localFile.childImageSharp.fixed
      : menuQuery.nmaternity.image.localFile.childImageSharp.fixed

  const renderNewArrivals = () => {
    return (
      <>
      <div className={hoverMenuStyles.renderNewArrivalsProduct}>

        {renderNewArrivalsProduct()}
      </div>
        <div className={hoverMenuStyles.newArrivalsSelector}>
          <button
            className={hoverMenuStyles.newArrivalsSelectorBox}
            onClick={() => setNewArrivalsCollection("Women")}
            style={{
              backgroundColor:
                newArrivalsCollection === "Women" ? "white" : "transparent",
              border:
                newArrivalsCollection === "Women"
                  ? "2px solid #70116b"
                  : "2px solid white",
              color: newArrivalsCollection === "Women" ? "#70116b" : "white",
            }}
          >
            <span className={hoverMenuStyles.newArrivalsSelectorText}>
              Women
            </span>
          </button>
          <button
            className={hoverMenuStyles.newArrivalsSelectorBox}
            onClick={() => setNewArrivalsCollection("Plus")}
            style={{
              backgroundColor:
                newArrivalsCollection === "Plus" ? "white" : "transparent",
              border:
                newArrivalsCollection === "Plus"
                  ? "2px solid #70116b"
                  : "2px solid white",
              color: newArrivalsCollection === "Plus" ? "#70116b" : "white",
            }}
          >
            <span className={hoverMenuStyles.newArrivalsSelectorText}>
              Plus
            </span>
          </button>
          <button
            className={hoverMenuStyles.newArrivalsSelectorBox}
            onClick={() => setNewArrivalsCollection("Maternity")}
            style={{
              backgroundColor:
                newArrivalsCollection === "Maternity" ? "white" : "transparent",
              border:
                newArrivalsCollection === "Maternity"
                  ? "2px solid #70116b"
                  : "2px solid white",
              color:
                newArrivalsCollection === "Maternity" ? "#70116b" : "white",
            }}
          >
            <span className={hoverMenuStyles.newArrivalsSelectorText}>
              Maternity
            </span>
          </button>
        </div>
        <Link
          className={hoverMenuStyles.newArrivalsMainImage}
          to={`/new-arrivals/${newArrivalsCollection}`
            .replace(/ /g, "-")
            .toLowerCase()}
        >
          <Img alt={newArrivalsCollection} fixed={newCollectionMainImg} />
        </Link>
      </>
    )
  }

  const renderNewArrivalsProduct = () => {
    return newCollectionData.map((product, i) => {
      if (i < 3) {
        let link = product.options[1]
          ? `/${newArrivalsCollection}/${product.handle}-${product.options[1].values[0]}`
              .replace(/ /g, "-")
              .toLowerCase()
          : `/${newArrivalsCollection}`.replace(/ /g, "-").toLowerCase()
          
          let color = product.options[1].values[0]
          let sizeID = product.variants
            .filter(variant => variant.selectedOptions[1].value === color)
            .map(variant => [variant.selectedOptions[0].value, variant.shopifyId])
        return (
          <Link
            key={`NewArrivlsNav-${product.title}-${i}`}
            className={hoverMenuStyles.newArrivalProduct}
            to={link}
          >
            <ProductCard
              imgHeight={225}
              imgWidth={150}
              textWidth={150}
              type="fixed"
              text="side"
              title={product.title}
              price={product.priceRange.minVariantPrice.amount}
              compareAt={product.variants[0].compareAtPrice}
              sizes={sizeID}
              imgFront={product.images[0].localFile.childImageSharp.fixed}
              imgBack={product.images[1].localFile.childImageSharp.fixed}
            />
          </Link>
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      {!isMobile ? (
        <div
          className={hoverMenuStyles.container}
          onMouseEnter={() => setHoverCollection(collection)}
          onMouseLeave={() => setHoverCollection(null)}
          role="presentation"
        >
          {collection === "New Arrivals" ? (
            renderNewArrivals()
          ) : (
            <>
              <Link
                className={hoverMenuStyles.collection}
                to={
                  collection === "Women"
                    ? "/women"
                    : collection === "Maternity"
                    ? "/maternity"
                    : collection === "Plus"
                    ? "/plus"
                    : "/new-arrivals"
                }
              >
                <Img
                  alt={collection ? collection : "Collection Image"}
                  className={hoverMenuStyles.collectionImage}
                  fixed={collectionImage}
                />
              </Link>
              <div className={hoverMenuStyles.innerContainer}>
                {renderCollection()}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={hoverMenuStyles.popUpContainer}>
          <div className={hoverMenuStyles.popUpBox}>
            <button
              onClick={handleClose}
              className={hoverMenuStyles.popUpClose}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            {collection === "New Arrivals" ? (
              <div className={hoverMenuStyles.newArrivalGrid}>
                {renderNewArrivals()}
              </div>
            ) : (
              <>
                <Link
                  className={hoverMenuStyles.collection}
                  to={
                    collection === "Women"
                      ? "/women"
                      : collection === "Maternity"
                      ? "/maternity"
                      : collection === "Plus"
                      ? "/plus"
                      : "/new-arrivals"
                  }
                >
                  <Img
                    alt={collection ? collection : "Collection Image"}
                    className={hoverMenuStyles.collectionImage}
                    fixed={collectionImage}
                  />
                  <div className={hoverMenuStyles.allProductsBg}>
                    <span className={hoverMenuStyles.allProducts}>
                      All Products
                    </span>
                  </div>
                </Link>
                <div className={hoverMenuStyles.innerContainer}>
                  {renderCollection()}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default HoverMenu
