import React from 'react';

import burgerStyles from './burger.module.scss'

function Burgermenu(props) {
  return (
    <button name="Burger Menu" className={burgerStyles.wrapper} onClick={props.handleNavbar}>
      <div className={ props.navbarState ? burgerStyles.open : "" }>
        <span className={props.navbarState ? (`${burgerStyles.openSpanOne} ${burgerStyles.spanOne}`) : burgerStyles.spanOne}>&nbsp;</span>
        <span className={props.navbarState ? (`${burgerStyles.openSpanTwo} ${burgerStyles.spanTwo}`) : burgerStyles.spanTwo}>&nbsp;</span>
        <span className={props.navbarState ? (`${burgerStyles.openSpanThree} ${burgerStyles.spanThree}`) : burgerStyles.spanThree}>&nbsp;</span>
      </div>
    </button>
  );
}

export default Burgermenu;
