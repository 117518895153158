import React, { useEffect, useState } from "react"
import layoutStyles from "./layout.module.scss"

import Header from "../header/header.js"
import Footer from "../footer/footer.js"
import { animateScroll as scroll } from "react-scroll"
import { useSpring, a, config } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons"
// import ContextProvider from "../../utilities/context/contextProvider"

function Layout({ children }) {
  const [isScrolled, setIsScrolled] = useState(false)

  //Listener for scroll to top button
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => setIsScrolled(window.scrollY > 0),
      false
    )

    // Clean-up
    return window.removeEventListener(
      "scroll",
      () => setIsScrolled(window.scrollY > 0),
      false
    )
  })

  const showScrollToTop = useSpring({
    bottom: isScrolled ? "10px" : "-100px",
    config: config.wobbly,
  })

  const [sticky, setSticky] = useState(false)

  //Listener for announcement bar
  useEffect(() => {
    //Adds resize event listener
    window.addEventListener(
      "scroll",
      () =>
        setSticky(
          window.pageYOffset >=
            document.getElementById("navigation-bar").offsetTop &&
            window.pageYOffset >= 30
        ),
      false
    )

    // Clean-up
    return window.removeEventListener(
      "scroll",
      () =>
        setSticky(
          window.pageYOffset >=
            document.getElementById("navigation-bar").offsetTop
            &&
            window.pageYOffset >= 30
        ),
      false
    )
  })

  return (
    <div className={layoutStyles.layoutContainer}>
      <Header sticky={sticky} />
      <div
        className={layoutStyles.content}
        style={{
          paddingTop: sticky ? "60px" : "0px",
        }}
      >
        {children}
      </div>
      <Footer />
      <a.button
        style={showScrollToTop}
        className={layoutStyles.scrollToTopContainer}
        onClick={() => scroll.scrollToTop()}
      >
        <FontAwesomeIcon icon={faChevronCircleUp} />
      </a.button>
    </div>
  )
}

export default Layout
