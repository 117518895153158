import React, { useState } from "react"

import Navbar from "./navBar/navbar.js"
import Announcement from "./announcement/announcement.js"

function Header({ sticky }) {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen)
  }

  const [cartOpen, setCartOpen] = useState(false)

  const handleCart = () => {
    setCartOpen(!cartOpen)
  }

  return (
    <header>
      <Announcement />
      <Navbar
        sticky={sticky}
        id="navigation-bar"
        navbarState={navbarOpen}
        handleNavbar={handleNavbar}
        cartState={cartOpen}
        handleCart={handleCart}
      />
    </header>
  )
}

export default Header
